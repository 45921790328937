import React from 'react';
import { graphql } from 'gatsby';
import LegalPage from 'components/pages/LegalPage';

const seo = {
  title: 'Q°emotion - Terms and conditions',
  keywords: [],
  description:
    'Q°emotion is a semantic analysis solution which transform your customer reviews into emotional anlytics and insights ! Make sure to read our Terms & Conditions.',
};

export default props => <LegalPage {...props} seo={seo} />;

export const pageQuery = graphql`
  query {
    dataBlockText: markdownRemark(
      frontmatter: { pageType: { eq: "termsConditions" }, lang: { eq: "en" } }
    ) {
      rawMarkdownBody
    }
  }
`;
